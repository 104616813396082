<template>
  <div
    ref="selectRef"
    class="select"
    :class="baseSelectClass"
    tabindex="0"
    @blur="handleBlur"
  >
    <span
      v-if="styled && label"
      class="select__label"
      @click.stop="onToggleSelect"
    >
      {{ label }}
    </span>
    <div class="select-field">
      <div
        class="select-field__control"
        :class="{ focused: isFocus }"
        @click="onToggleSelect"
      >
        <div class="select-field__prepend">
          <slot name="prepend"></slot>
        </div>
        <div class="select-field__native">
          <input
            ref="inputRef"
            :value="inputValue"
            class="select-field__input"
            :class="[{ 'use-input': useInput && styled }]"
            type="text"
            :placeholder="placeholder"
            :readonly="!useInput"
            @input="$emit('update:inputValue', $event?.target?.value)"
            @blur="handleBlur"
          />
          <span
            v-if="!useInput"
            class="select-field__value"
            :class="[{ link: link }, { 'upper-case': upperCase }]"
          >
            <slot name="value">
              {{ modelValue?.value ? modelValue?.value : "Выбрать" }}
            </slot>
          </span>
        </div>
        <div v-if="arrow && !useInput" class="select-field__append">
          <BaseIcon
            icon="arrow"
            class="select-field__arrow"
            :class="{ 'select-field__arrow_up': isFocus }"
          />
        </div>
      </div>
      <Transition name="select">
        <div v-if="isFocus" class="select-options" :class="optionsStyles">
          <template v-if="options.length">
            <slot name="first-option"></slot>
            <div
              v-for="option in options"
              :key="`select-${option?.id}`"
              class="select-option"
              :class="{
                'select-option_selected': selectedOptionClass(option),
                'select-option_disabled ': option?.isAvailable === false,
              }"
              @click="changeOption(option)"
              v-tooltip.top="
                isTooltip &&
                checkOptionWidthForShowingTooltip(option.id) &&
                $t(option.value)
              "
            >
              <div
                v-if="checkbox"
                class="select-option__checkbox checkbox"
                :class="{
                  checkbox_checked: selectedOptionClass(option),
                }"
              ></div>
              <div class="select-option__value">
                <div
                  class="select-option__value-text"
                  :class="[{ 'upper-case': upperCase }]"
                >
                  <div
                    class="select-option__text"
                    :id="option.id"
                    ref="optionText"
                  >
                    <slot name="option" :option="option">
                      <span>{{ option.value }}</span>
                    </slot>
                  </div>
                  <div
                    v-if="!option.isAvailable && option.reason"
                    class="select-option-info"
                    v-tooltip.bottom="isTooltip && option.reason"
                  >
                    <BaseIcon class="select-option-info__icon" icon="info" />
                  </div>
                </div>
                <span v-if="option.details && styled">
                  {{ option.details }}
                </span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="select-option">
              <SpinnerRadial v-if="isLoadSearch" size="30" />
              <span v-else>{{ $t("No option") }}</span>
            </div>
          </template>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from "vue";

import { ref, computed } from "vue";
import BaseIcon from "~/components/icons/BaseIcon.vue";
import BaseCheckbox from "~/components/input/BaseCheckbox.vue";
import SpinnerRadial from "~/components/loaders/SpinnerRadial.vue";

export type BaseSelectOption = {
  value: string;
  id: string;
  isAvailable?: boolean;
  reason?: string;
  path?: string;
  details?: string | null;
};

export default defineNuxtComponent({
  components: { SpinnerRadial, BaseCheckbox, BaseIcon },
  props: {
    modelValue: {
      type: [Object, Array] as PropType<BaseSelectOption | BaseSelectOption[]>,
      default: null,
    },
    options: {
      type: Array as PropType<BaseSelectOption[]>,
      default: [],
    },
    inputValue: {
      type: String as PropType<string>,
    },
    useInput: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    multiple: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    upperCase: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    search: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    checkbox: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    arrow: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    label: {
      type: String as PropType<string>,
      default: null,
    },
    icon: {
      type: String as PropType<string>,
      default: null,
    },
    listPositionTop: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    link: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    styled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isLoadSearch: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    placeholder: {
      type: String as PropType<string | undefined>,
      default: null,
    },
    isTooltip: {
      type: Boolean,
      default: null,
    },
  },
  emits: ["change", "update:inputValue"],

  setup(props, { emit }) {
    const selectRef = ref<HTMLElement | null>(null);
    const inputRef = ref<HTMLElement | null>(null);
    const isFocus = ref(false);
    const optionText = ref<HTMLDivElement[] | []>([]);

    watch(
      () => props.inputValue,
      () => {
        if (inputRef.value === document.activeElement) {
          isFocus.value = true;
        }
      }
    );
    watch(
      () => isFocus.value,
      async () => {
        if (props.inputValue === "") {
          changeOption({ id: "", value: "" });
        }
      }
    );

    const checkOptionWidthForShowingTooltip = (
      id: string
    ): boolean | undefined => {
      if (!optionText.value.length) {
        return;
      }

      const element = optionText.value.find((option) => option.id === id);

      if (!element) {
        return;
      }

      return element.scrollWidth > element.offsetWidth;
    };

    function handleBlur(e: FocusEvent) {
      if (props.useInput) {
        const classname =
          (e.relatedTarget as HTMLElement)?.classList?.[2] !== "use-input";
        if (classname) {
          isFocus.value = false;
        }
      } else {
        isFocus.value = false;
      }
    }

    const optionsStyles = computed(() => ({
      top: props.listPositionTop,
    }));
    const baseSelectClass = computed(() => ({
      "styled-select": props.styled,
      "use-input": props.useInput,
    }));
    const selectedOptionClass = (option: BaseSelectOption): boolean => {
      if (Array.isArray(props.modelValue)) {
        return props.modelValue.some((e) => e?.id === option?.id);
      } else {
        return props.modelValue?.id === option.id;
      }
    };

    const onToggleSelect = () => {
      if (selectRef.value && isFocus.value) {
        isFocus.value = false;
      } else {
        if (props.useInput) {
          inputRef.value?.focus();
        }
        isFocus.value = true;
      }
    };

    const changeOption = (value: BaseSelectOption) => {
      if (value?.isAvailable !== false) {
        emit("change", value);
        if (!props.multiple && !!selectRef.value) {
          isFocus.value = false;
        }
      }
    };

    return {
      isFocus,
      baseSelectClass,
      selectRef,
      inputRef,
      optionsStyles,
      selectedOptionClass,
      handleBlur,
      onToggleSelect,
      changeOption,
      optionText,
      checkOptionWidthForShowingTooltip,
    };
  },
});
</script>
